<template>
  <div class="lazy-scroll">
    <div class="wrapper-terms center-text">
      <h1 class="center-text uppercase">{{lang[this.appLang].buy_tapni}}<br></h1>
      <h3 class="small-top-padding" v-html="lang[this.appLang].products_p1"></h3>
	  <hr class="decoration decoration-lines-thin"/>
	    <a href="https://tapni.co/collections/cards?ref=app"
	       style="width:100%; border-radius: 20px; background-size: cover; background-position: center; height: 240px; background-image: linear-gradient(to bottom, rgba(245,246,252,0), rgba(0,0,0,0.73)), url('https://cdn.tapni.co/images/cards.png');"
	       class="center-text edit-shadow">
		    <h2 class="color-white bold" style="position: absolute; left: 15px; bottom: 10px;">
				{{lang[this.appLang].cards}}
		    </h2>
		    <span class="button white-button edit-shadow font-13" style="position: absolute; right: 10px; top: 25px;">
		  {{lang[this.appLang].explore_collection}}
		</span>
	    </a>
	  <hr class="decoration decoration-lines-thin">
	    <a href="https://tapni.co/collections/stickers?ref=app"
	       style="width:100%; border-radius: 20px; background-size: cover; background-position: center; height: 240px; background-image: linear-gradient(to bottom, rgba(245,246,252,0), rgba(0,0,0,0.73)), url('https://cdn.tapni.co/images/stickers.png');"
	       class="center-text edit-shadow">
		    <h2 class="color-white bold" style="position: absolute; left: 15px; bottom: 10px;">
				{{lang[this.appLang].stickers}}
		    </h2>
		    <span class="button white-button edit-shadow font-13" style="position: absolute; right: 10px; top: 25px;">
		  {{lang[this.appLang].explore_collection}}
		</span>
	    </a>
	  <hr class="decoration decoration-lines-thin"/>
	  <a href="https://tapni.co/collections/keychains?ref=app"
	     style="width:100%; border-radius: 20px; background-size: cover; background-position: center; height: 240px; background-image: linear-gradient(to bottom, rgba(245,246,252,0), rgba(0,0,0,0.73)), url('https://cdn.tapni.co/images/keychains.png');"
	     class="center-text edit-shadow">
		<h2 class="color-white bold" style="position: absolute; left: 15px; bottom: 10px;">
			{{lang[this.appLang].keychains}}
		</h2>
		<span class="button white-button edit-shadow font-13" style="position: absolute; right: 10px; top: 25px;">
		  {{lang[this.appLang].explore_collection}}
		</span>
	  </a>
      <hr class="decoration decoration-lines-thin"/>
	  <p class="tapni-p margin-bottom-100"></p>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  name: 'Devices',
  data () {
    return {
      language: this.$store.getters.langShortcut,
      terms: ''
    }
  },
  methods: {
    ...mapActions(['eventLog'])
  },
  async mounted () {
    this.eventLog({
      type: 'connections_page'
    })
    this.$store.commit('setInitialize', true)
    this.$store.commit('setLoading', false)
  }
}
</script>

<style>
</style>
